<template>
  <div>

    <div class="ml-5 mt-5 mb-5">  
      <p style="font-size: 48px; font-weight: bolder;">
        Dashboard
      </p>
    </div>
    <div class="ml-5 mt-5 mb-5">  
      <p style="font-size: 24px; font-weight: 100; font-weight: bolder; ">
        Current Event
      </p>
    </div>
    <div v-if="loadingData" class="ml-5 mt-5 mb-5"> กำลังโหลดข้อมูล ...</div>
    <div v-else-if="eventListCurrent.length > 0" class="ml-1">
      <CRow class="d-flex align-items-center justify-content-left ml-5" >
          <CCol align="left"  sm="9" md="9" lg="9" xl="4" v-for="event in eventListCurrent" :key="event.eventid">
            <CCard  bodyWrapper style="border-radius: 16px; background-color: #D7C5FF; height: 250px;"  >
                <div class="d-flex" style="text-align: left;">
                  <div class="col-sm-12 text-left" >
                    <CBadge style="font-size: 24px; font-weight: bolder;" color="" class="animated pulse">
                    <!-- <a  style="font-size: 24px; font-weight: bolder;"> -->
                      <img
                        alt="service-logo"
                        color="outline-pastelpurple2"
                        src="../../public/img/service/exam-logo.png"
                        height="36"
                        width="36"
                        class=""
                        v-if="event.eventDetail.srcExam.service ==='cmuexam'"
                      />
                      <img
                        alt="service-logo"
                        color="outline-pastelpurple2"
                        src="../../public/img/service/mango-logo.png"
                        height="20"
                        style="margin-top: 8px; margin-bottom: 8px;"
                        width="50"
                        class=""
                        v-if="event.eventDetail.srcExam.service ==='cmumango'"
                      /> :
                     {{event.eventName}}
                      
                    
                    <!-- </a>  -->
                  </CBadge>

                  </div>
                </div>
                <div class="mt-2 mb-4">
                  <div class="col-sm-12 text-left" >
                    <a  style="font-size: 14px; font-weight: 600;">วันสอบ </a>
                    <br/>
                    <a  style="font-size: 14px; font-weight: 800;">เวลา {{`${timeFormat(event.eventDetail
                        .startAt)} - ${timeFormat(event.eventDetail
                        .endAt)}` }}<br/>{{` ${timeThaiFormat(event.startAt)}`}} 
                       
                    </a><br/>
                    <a  style="font-size: 10px; font-weight: 300;">(สร้างเมื่อ {{`${timeThaiFormat(event.createdAt)}`}})</a>

                  </div>
                </div>
                <div class="d-flex" style="text-align: right;  bottom: 0.5rem;  right: 0.5rem ; position: absolute;">

                  <div class="text-right" >
                    <CButton color="warning" class="float-right" v-on:click="btnClicked(event.eventid ? event.eventid : event._id)">
                      ไปที่ Event
                    </CButton>
                  </div>
                </div>
            </CCard>
          </CCol>
      </CRow>
    </div>
    <CRow v-else class="ml-5">
     <CCol align="center">
       <CCard class="w-100 center"  bodyWrapper col="6" style="border-radius: 16px;" >
           <div class="d-flex justify-content-center" style="text-align: center;">
             <div class="col-sm-6 text-center" style="padding: 5rem;">
               <label  style="font-size: 24px;">ยังไม่มี Event ที่กำลังดำเนินการ</label>
             </div>
           </div>
       </CCard>
       <CCard class="w-100 center pl-4" bodyWrapper   style="border-radius: 16px; height: 229px; " v-if="eventListCurrent.length + eventListOther.length <= 0 ">
         <CRow>
           <div style="margin-left: auto; margin-right: auto; margin-top: auto;text-align: center;">
             <p class="m-4" style="font-size: 40px; font-weight: bolder;">ยังไม่มี events</p>
             <p class="m-4" style="font-size: 20px;">สร้าง Event การคุมสอบ</p>
             <CButton size="lg" color="pastelpurple2" class="mt-auto text-white" to="/events?opencreate=true" style="width: 100%">
               <i class="fas fa-plus"></i>
               Create Event
             </CButton>
           </div>
         </CRow>
       </CCard>
     </CCol>
    </CRow>

    <div class="ml-5 mt-5 mb-5">  
      <p style="font-size: 24px; font-weight: 100; font-weight: bolder;">
        Other Event
      </p>
    </div>
    <CRow class="d-flex align-items-center justify-content-left ml-5">
      <CCol align="center"  sm="9" md="9" lg="9" xl="4"  v-for="event in eventListOther.slice(0,5)" :key="event.eventid">
        <CCard  bodyWrapper style="border-radius: 16px; background-color: #D7C5FF; height: 250px;"  >
                <div class="d-flex" style="text-align: left;">
                  <div class="col-sm-12 text-left" >
                    <a  style="font-size: 24px; font-weight: bolder;">
                      <img
                        alt="service-logo"
                        color="outline-pastelpurple2"
                        src="../../public/img/service/exam-logo.png"
                        height="36"
                        width="36"
                        class=""
                        v-if="event.eventDetail.srcExam.service ==='cmuexam'"
                      />
                      <img
                        alt="service-logo"
                        color="outline-pastelpurple2"
                        src="../../public/img/service/mango-logo.png"
                        height="20"
                        style="margin-top: 8px; margin-bottom: 8px;"
                        width="50"
                        class=""
                        v-if="event.eventDetail.srcExam.service ==='cmumango'"
                      /> :
                      {{event.eventName}}   
                      <!-- <img
                        src="../assets/icons/live.gif"
                        height="30"
                        width="70"
                        class=""
                      /> -->
                    </a> 
                  </div>
                </div>
                <div class="mt-2 mb-4">
                  <div class="col-sm-12 text-left" >
                    <a  style="font-size: 14px; font-weight: 600;">วันสอบ </a>
                    <br/>
                    <a  style="font-size: 14px; font-weight: 800;">เวลา {{`${timeFormat(event.eventDetail
                        .startAt)} - ${timeFormat(event.eventDetail
                        .endAt)}` }}<br/>{{` ${timeThaiFormat(event.startAt)}`}} 
                       
                    </a><br/>
                    <a  style="font-size: 10px; font-weight: 300;">(สร้างเมื่อ {{`${timeThaiFormat(event.createdAt)}`}})</a>

                  </div>
                </div>
                <div class="d-flex" style="text-align: right;  bottom: 0.5rem;  right: 0.5rem ; position: absolute;">

                  <div class="text-right" >
                    <CButton color="warning" class="float-right" v-on:click="btnClicked(event.eventid ? event.eventid : event._id)">
                      ไปที่ Event
                    </CButton>
                  </div>
                </div>
            </CCard>
        <!-- <CCard   bodyWrapper class="w-100" style="border-radius: 16px; background-color: #D7C5FF; " >
            <v-row align="center"
                   justify="center" >
              <CCol sm="12" md="12" lg="3" class="w-100 p-1 d-flex align-items-center justify-content-center" align="center">
                    <a  style="font-weight:bolder; font-size: 18px;">{{event.eventName}}</a>
              </CCol>
              <CCol sm="12" md="12" lg="6" class="w-100 p-1 d-flex align-items-center justify-content-center">

                  <b-container class="justify-content-center">
                  <b-row>
                    <b-col  class="w-100 d-flex align-items-center justify-content-center">
                      <a style="font-weight:bolder; font-size: 18px;">วันสอบ{{` ${timeThaiFormat(event.startAt)}`}} <a style="font-weight:bolder; font-size: 18px;">เวลา {{` ${timeFormat(event.startAt)}`}}</a></a>
                    </b-col>
                  </b-row>
                  
                  <b-row v-if="event.eventDetail.srcExam.service ==='cmuexam'">
                    <b-col class="w-100 d-flex align-items-center justify-content-center">
                      <img
                        alt="service-logo"
                        color="outline-pastelpurple2"
                        src="../../public/img/service/exam-logo.png"
                        height="80"
                        width="90"
                        class=""
                      />
              
                    </b-col>
                  </b-row>
                  <b-row v-if="event.eventDetail.srcExam.service ==='cmumango'">
                    <b-col class="w-100 d-flex align-items-center justify-content-center">
                      <img
                        alt="service-logo"
                        color="outline-pastelpurple2"
                        src="../../public/img/service/mango-logo.png"
                        height="30"
                        width="70"
                        class="m-4"
                      />
              
                    </b-col>
                  </b-row>
                  <b-row v-if="event.eventDetail.srcExam.service ==='cmumoodle'">
                    <b-col class="w-100 d-flex align-items-center justify-content-center">
                      <img
                        alt="service-logo"
                        color="outline-pastelpurple2"
                        src="../../public/img/service/moodle-logo.png"
                        height="40"
                        width="90"
                        class="m-4"
                      />
              
                    </b-col>
                  </b-row>
                  <b-row v-if="event.eventDetail.srcExam.service ==='cmucanvas'">
                    <b-col class="w-100 d-flex align-items-center justify-content-center">
                      <img
                        alt="service-logo"
                        color="outline-pastelpurple2"
                        src="../../public/img/service/canvas-cmu-logo.png"
                        height="80"
                        width="90"
                        class=""
                      />
              
                    </b-col>
                  </b-row>
               
                  <b-row>
                    <b-col class="w-100 d-flex align-items-center justify-content-center">
                      <a style="font-size: 10px;">สร้างเมื่อ {{` ${timeThaiFormat(event.createdAt)}`}}</a>
                    </b-col>
                  </b-row>
                </b-container>
              </CCol>
                    
              <CCol sm="12" md="12" lg="3" class="w-100 p-1 d-flex align-items-center justify-content-center">
                  <div class="text-right" >
                    <CButton color="warning" class="float-right" v-on:click="btnClicked(event.eventid ? event.eventid : event._id)">
                      <b>Monitor</b>
                    </CButton>
                  </div>
              </CCol>
             </v-row>
        </CCard> -->
        
      </CCol>
    </CRow>
  </div>
</template>

<script>
import EventTableList from "./events/EventTableList"
import examManangerApi from "@/services/apiServices"
import CryptoJS from 'crypto-js'
import Swal from "sweetalert2"

export default {
  name: "Dashboard",
  components: {
    EventTableList,
  },
  created() {
    
    let role = CryptoJS.AES.decrypt(localStorage.getItem("Admin"), process.env.VUE_APP_LOCALSTORAGE_KEY).toString(CryptoJS.enc.Utf8)
    if (role === "super-admin") {
      if (this.$route.query.geteventof) {
        this.isAdmin = true
        if (this.$route.query.geteventof == "all") {
          this.getEventListALl()
        } else {
          this.getEventListByOwner(this.$route.query.geteventof)
        }
      } else {
        console.log("Get Event: Get Event of yourself")
        this.getEventListByOwner(localStorage.getItem("email"))
      }
    } else {
      this.getEventListByOwner(localStorage.getItem("email"))
    }
    
  },
  data() {
    return {
      loadingData : true ,
      eventList: [],
      eventListOther: [],
      eventListCurrent: [] , 
      rowCurrentEvent : 0
    }
  },
  methods: {
    async getEventListByOwner(email) {
      Swal.showLoading()
      let eventList = await examManangerApi.getCollaboratorListByUser(email)
      console.log("check" ,eventList )
      if (eventList["status"]) {
        this.eventList = []
        eventList["data"].sort(function(a, b) {
          let keyA = new Date(a.updatedAt),
          keyB = new Date(b.updatedAt)
          if (keyA < keyB) return 1
          if (keyA > keyB) return -1 
          return 0
        })
        let date = new Date().toLocaleDateString();
        for (let event of eventList["data"]) {
          let tmp = await examManangerApi.getEventById(event.eventid)
          event["startAt"] = tmp.startAt
          if(date === new Date(tmp.startAt*1000).toLocaleDateString()){
              this.eventListCurrent.push(event);
          }else{
            this.eventListOther.push(event);
          }
        }
        this.rowCurrentEvent = Math.trunc(this.eventListCurrent.length / 3) 
        this.loadingData = false
        Swal.close()
      }
      // console.log(this.eventList)
    },
    btnClicked(index) {
      this.$router.push({ path: `events/${index}/detail` })
    },
    color(value) {
      let $color
      if (value <= 25) {
        $color = "info"
      } else if (value > 25 && value <= 50) {
        $color = "success"
      } else if (value > 50 && value <= 75) {
        $color = "warning"
      } else if (value > 75 && value <= 100) {
        $color = "danger"
      }
      return $color
    },
    timeThaiFormat(timestamp) {
      let option = { year: "numeric", month: "long", day: "numeric" ,weekday: "long" }

      return new Date(parseInt(timestamp) * 1000).toLocaleString("th-TH", option)
    },
    getServiceImage (service) {
        console.log(service)
        let images = ""
        switch (service) {
          case "msteams":
            images = require.context('../../public/img/service', false, /\.png$/)
            return images('./' + 'microsoft-teams-logo' + ".png")
          case "canvas":
            images = require.context('../../public/img/service', false, /\.png$/)
            return images('./' + 'canvas-logo' + ".png")
          case "moodle":
            images = require.context('../../public/img/service', false, /\.png$/)
            return images('./' + 'moodle-logo' + ".png")
          case "mango":
            images = require.context('../../public/img/service', false, /\.png$/)
            return images('./' + 'mango-logo' + ".png")
          case "cmuexam":
            return images('../../public/img/service/exam-logo.png')
          default:
            break;
        }
    },
    timeFormat(timestamp) {
      let timeFormat = {  hour: "numeric", minute: "numeric", second: "numeric" }

      return new Date(parseInt(timestamp) * 1000).toLocaleString("th-TH", timeFormat)
    },
  },
}
</script>
<style scoped>
.text-white {
  color: aliceblue;
}
.custom-this-orange {
  color: orange !important;
}
</style>
